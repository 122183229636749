import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFS404Page from '../PFS404Page/PFS404Page';
import PFSWhatWeDoFinOps from '../PFSWhatWeDoFinOps/PFSWhatWeDoFinOps';
import PFSWhatWeDoIndex from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import PFSWhatWeDoLegal from '../PFSWhatWeDoLegal/PFSWhatWeDoLegal';
import PFSWhatWeDoPeopleOps from '../PFSWhatWeDoPeopleOps/PFSWhatWeDoPeopleOps';
import PFSWhatWeDoTaxes from '../PFSWhatWeDoTaxes/PFSWhatWeDoTaxes';

// tax tool pages
import PFSTaxRates from '../PFSTaxRates/PFSTaxRates';
import PFSDueDates from '../PFSDueDates/PFSDueDates';
import PFSRecordRetentions from '../PFSRecordRetentions/PFSRecordRetentions';

const PFSWhatWeDoRoutes = () => (
  <Routes>
    <Route path='FinOps' element={<PFSWhatWeDoFinOps />} />
    <Route exact path='TaxOps' element={<PFSWhatWeDoTaxes />} />
    <Route path='TaxOps/*'>
      <Route path='tax-rate-tool' element={<PFSTaxRates />} />
      <Route path='due-dates-tool' element={<PFSDueDates />} />
      <Route path='tax-guides-tool' element={<PFSRecordRetentions />} />
      <Route path='*' element={<PFS404Page />} />
    </Route>
    <Route path='PeopleOps' element={<PFSWhatWeDoPeopleOps />} />
    <Route path='LegalOps' element={<PFSWhatWeDoLegal />} />
    {/* <Route path='technology' element={<PFSWhatWeDoTechnology />} /> */}
    <Route path='/' element={<PFSWhatWeDoIndex />} />
    <Route path='*' element={<PFS404Page />} />
  </Routes>
);

PFSWhatWeDoRoutes.propTypes = {};

PFSWhatWeDoRoutes.defaultProps = {};

export default PFSWhatWeDoRoutes;
