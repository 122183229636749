import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import styles from './PFSWhatWeDoLegal.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import legalImage from './legal.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';
 
const PFSWhatWeDoLegal = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'Legal Operations',
      url: pathname,
    },
  ]);  
  return(
  <div className={styles.PFSWhatWeDoLegal} data-testid='PFSWhatWeDoLegal'>
    <ContentContainer>
      <PFSServiceHeader subtitle='Legal Operations' title='Solving for the Independent Insurance Agency.' />
      <div className={globalStyles.IntroContainer}>
        <img src={legalImage} alt='' className={globalStyles.IntroImage} />
        <div className={globalStyles.IntroText}>
          <p>At PFS Law, APC, we offer comprehensive legal services for independent insurance agencies and their owners. We take a client-centric approach to deliver customized solutions that meet your requirements.</p>
          <p>Our commitment to excellence, and our dedication to your success ensures that we achieve our legal objectives, providing peace of mind. Contact us today to learn how we can assist you.</p>
        </div>
      </div>
      <PFSServicePoints
        title='Business Transactions'
        points={[
          'Mergers and Acquisitions (Buying or Selling an Agency)',
          'Document Drafting: Bylaws, Operating Agreement, or Partnership Agreement',
          'Entity Formations',
        ]}
      />
      <PFSServicePoints
        title='Business Owner Compliance'
        points={[
          'Agent for Service of Process in each state',
          'Annual Secretary of State Filings in each state',
          {
            label: 'Annual meeting with attorney to review and update:',
            subpoints: ["Meeting Minutes", "Bylaws, Operating Agreement, or Partnership Agreement", "Stock Certificates"]
          },
          'Annual Beneficial Ownership Information Reporting'
        ]}
      />
      <PFSServicePoints
        title='Trust and Estate'
        points={[
          'Estate Planning',
          'Trust Reviews',
          'Trust Drafting',
          'Will Drafting',
        ]}
      />
    </ContentContainer>
    <PFSWhyChoosePFS />
    <PFSMeetTheTeam department='Legal' />
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSWhatWeDoLegal.propTypes = {};

PFSWhatWeDoLegal.defaultProps = {};

export default PFSWhatWeDoLegal;
