import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import globalStyles from '../../styles/global.module.css';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import calculatorImage from './calculator.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoFinOps = () => {
  const { pathname } = useLocation();

  useBreadcrumb([ WhatWeDoBreadcrumb, {
    label: "Financial Operations",
    url: pathname
  } ]);

  return (
    <div
      data-testid='PFSWhatWeDoFinOps'
    >
      <ContentContainer>
        <PFSServiceHeader title='Solving for the Independent Insurance Agency.' subtitle='Financial Operations' />
        <div className={globalStyles.IntroContainer}>
          <img alt='A calculator' src={calculatorImage} className={globalStyles.IntroImage} />
          <div className={globalStyles.IntroText}>
            <p>At AgencyPoint, we understand that managing the financial operations of your independent insurance agency can be complex and time-consuming. That's why our FinOps team offers expert services tailored to your specific needs. By leveraging your Agency Management System effectively, we provide the tools and insights necessary to make informed decisions for your agency's success.</p>
            <p>With AgencyPoint, you can trust that your financial operations are in expert hands, freeing you to focus on what you do best: running your agency. Contact us today to discover how we can support your financial goals.</p>
          </div>
        </div>
        <PFSServicePoints
          title='Bookkeeping - Income Tax Cash Basis'
          points={[
            'Trust Reconciliation',
            'Agency Bill Processing',
            'Direct Bill Reconciliation',
            'Data Entry and Reconciliation of Operating Bank, Credit Card, and Loan Accounts',
            'Month-End Closing of Books',
            'Monthly Management Reports',
            '1099/1042 Processing',
          ]}
        />
        <PFSServicePoints
          title='Bookkeeping - Income Tax Accrual Basis (The Above Plus)'
          points={[
            'Accounts Payables',
            'Producers Payables',
            'Accounts Receivables',
            'Prepaid Expenses',
            'Accrued Expenses',
            'Deferred Expenses',
          ]}
        />
        <PFSServicePoints
          title='Bookkeeping - Generally Accepted Accounting Principles Basis (The Above Plus)'
          points={[
            'Asset Management',
            'Liability Management',
            'Revenue Recognition',
            'Business Combinations',
            'Leasing Standards',
            'Capitalization Table Management',
          ]}
        />

        <PFSServicePoints
          title='CFO Advisory (The Above Plus)'
          points={[
            'Financial Reporting Compliance',
            'Financial Statement Analysis',
            'Cost Reductions Analysis',
            'KPI Dashboard',
            'Financial Strategy Meetings',
            'Budget Development',
            'Financial Planning and Forecasting: for growth, for exit, for succession, for recession',
            'Cash Flow Forecasting',
            'Assurance Readiness: Audits, Reviews, Compilations',
            'Risk Management',
            'Contract Negotiations',
          ]}
        />
      </ContentContainer>
      <PFSWhyChoosePFS />
      <PFSMeetTheTeam department='FinOps' />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta ctaText='Call us today!' />
    </div>
  )
};

PFSWhatWeDoFinOps.propTypes = {};

PFSWhatWeDoFinOps.defaultProps = {};

export default PFSWhatWeDoFinOps;
